import React from "react"
import BtfData from "./data/btf_carbonate.json"
import Styles from "./css/btf_carbonate.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Carbonate_disc_search from "src/components/carbonate_disc_search"
import FooterLinksSingle from "@tightrope/footerlinks/footerlinks_single.js"

class BtfCarbonate extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (

      <div className = {Styles.btfCarb} style={{ display: this.state.display }}>

              <section className={Styles.bf}>
                <div className = {Styles.middle}>

                  <div className = {Styles.bottomInner}>
                  <h2 className = {Styles.subheadDark}>{this.state.data.midHeadline}</h2>

                  <p dangerouslySetInnerHTML={{ __html: this.state.data.description}}></p>

                  <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl} ${Styles.animCTA} ${Styles.cl1}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
                    <Carbonate_disc_search></Carbonate_disc_search>
                    </div>


                </div>{/*end middle*/}


                <div className = {Styles.bottom}>
                  <div className={Styles.features} id='features'></div>
                    <h2 className = {Styles.subheadDark}>{this.state.data.bottomHeadline}</h2>
                  <div className = {Styles.twocolInner}>
                    <div className = {Styles.left}>
                      <details>
                        <summary>{this.state.data.sum1}</summary>
                        <p>{this.state.data.p1}</p>
                      </details>

                      <details>
                        <summary>{this.state.data.sum2}</summary>
                        <p>{this.state.data.p2}</p>
                      </details>

                      <details>
                        <summary>{this.state.data.sum3}</summary>
                        <p>{this.state.data.p3}</p>
                      </details>

                      <details>
                        <summary>{this.state.data.sum4}</summary>
                        <p>{this.state.data.p4}</p>
                      </details>

                </div>	{/*end left*/}

                    <div className = {Styles.right}>

                    </div>
                      <caption className={Styles.caption}>{this.state.data.imageCaption}</caption>
                    </div>{/*end twocolInner*/}

                    <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl} ${Styles.animCTA} ${Styles.cl1}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
                    <Carbonate_disc_search></Carbonate_disc_search>
            </div>{/*end bottom*/}

                      <FooterLinksSingle></FooterLinksSingle>



          </section>


</div>




    )
  }
}

export default BtfCarbonate
